<template>
  <v-app class="login">
    <v-container>
      <v-row>
        <v-col cols="4">

        </v-col>
        <v-col cols="4" class="background_text">
          <div v-if="login">


            <h1 style="text-align: center">Login </h1>
            <v-form @submit.prevent="sig(login_send)" style="margin-top: 30px">
              <v-row>
                <v-col cols="12" v-if="error_login">
                  <v-alert type="error">
                   {{text_error_login}}
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Username"
                      outlined
                      v-model="login_send.username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Password"
                      outlined
                      type="password"
                      v-model="login_send.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn style="width: 100%"
                         depressed
                         color="primary"
                         type="submit"
                  >
                    Iniciar Sesión
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span style="cursor: pointer" @click="login = false">Registrarse</span>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <div v-else>
            <h1 style="text-align: center">Registro </h1>
            <v-form @submit.prevent="registerLog(register_send)" style="margin-top: 30px">
              <v-row>
                <v-col cols="12" v-if="error_register">
                  <v-alert type="error">
                    {{text_error_register}}
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Email"
                      outlined
                      v-model="register_send.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Nombre"
                      outlined
                      v-model="register_send.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Username"
                      outlined
                      v-model="register_send.username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Telefono"
                      outlined

                      v-model="register_send.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Contraseña"
                      outlined
                      type="password"
                      v-model="register_send.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn style="width: 100%"
                         depressed
                         color="primary"
                         type="submit"
                  >
                    Registrarme
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span style="cursor: pointer" @click="login = true">Login</span>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
        <v-col cols="4">
        </v-col>

      </v-row>
    </v-container>


  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Login",

  methods: {
    ...mapActions("auth", ["signin",'registerUser']),
    async sig(data) {
      this.error_login = false
      const response = await this.signin(data)
      if (response){
        console.log(response)
        this.error_login = true
        this.text_error_login = response
      }
    },
    async registerLog(data) {
      this.error_login = false
      const response = await this.registerUser(data)
      if (response === true){
        alert('Registro correctamente inicie sesion')
        this.login = true
        this.login_send.username = data.username
        this.login_send.password = data.password
      }else{
        this.error_register = true
        this.text_error_register = response
      }
    }

  },
  data() {
    return {
      text_error_register:'',
      error_register:false,
      error_login: false,
      text_error_login:'',
      login:true,
      register:false,
      login_send: {
        username: '',
        password: ''
      },
      register_send:{
        email:'',
        name:'',
        username:'',
        password:'',
        phone:'',
      }
    };
  },
  created() {
    if (this.getToken){
      console.log(this.getToken)
      this.$router.push('/')
    }
  },
  computed:{
    ...mapGetters('auth',['getToken'])
  }
};
</script>

<style>
.login {
  margin-top: 100px;
}

.loginImage {
  background-size: cover;
  width: 500px;
}

.background_text {
  background: whitesmoke;
  padding: 50px;
  border-radius: 10px;
  border: 1px solid grey;
  box-shadow: -4px 15px 25px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -4px 15px 25px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 15px 25px -10px rgba(0, 0, 0, 0.75);
}

</style>
